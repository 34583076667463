import React from 'react';
import classNames from 'classnames';

import styles from './Plan.module.scss';
import FeatureList from '../FeatureList/FeatureList';

const Plan = ({ plan }) => {

  return (
    <div className={classNames(styles.wrapper, styles[plan.style])}>
      <h1 className={classNames(styles.name, styles[plan.name.toLowerCase()])}>{plan.name}</h1>
      <h2 className={styles.price}>{plan.price}<span className={styles.timespan}>per month</span></h2>
      <FeatureList features={plan.features} />
      <button className={styles.button}>Choose</button>
    </div>
  );
};

export default Plan;
