import React from 'react';

import Layout from '../components/furniture/Layout/Layout';

import generateTitle from '../utils/generateTitle';
import Plan from '../components/plans/Plan/Plan';
import Plans from '../components/plans/Plans/Plans';

const plans = [
  {
    name: 'Starter',
    style: 'small',
    price: 'Free',
    features: ['Track <strong>3</strong> collections', 'Share your progress'],
  },
  {
    name: 'Pro',
    style: 'large',
    price: '$3.99',
    features: [
      'Track <strong>10</strong> collections',
      'Share your progress',
      'Compare your collections',
      'Exclusive badges'
    ],
  },
  {
    name: 'Ultimate',
    style: 'small',
    price: '$4.99',
    features: [
      'Tracked <strong>unlimited</strong> collections',
      'Share your progress',
      'Compare your collections',
      'Exclusive badges',
      'High resolution images (where available)',
      'Part of the early access program'
    ],
  },
];

const PlansPage = ({ location }) => {
  const pageTitle = generateTitle('Plans');

  return (
    <Layout location={location} pageTitle={pageTitle} hero={
      <Plans>
        {plans.map(plan => <Plan key={plan.price} plan={plan} />)}
      </Plans>
    }>
    </Layout>
  );
};

export default PlansPage;
