import React from 'react';

import styles from './FeatureList.module.scss';

const FeatureList = ({ features }) => {
  return (
    <div className={styles.wrapper}>
      <ul className={styles.list}>
        {features.map(feature => (
          <li className={styles.feature} dangerouslySetInnerHTML={{__html: feature}} />
        ))}
      </ul>
    </div>
  );
};

export default FeatureList;
