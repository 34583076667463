import React from 'react';

import styles from './Plans.module.scss';

const Plans = ({ children }) => {

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Start tracking your collection today!</h1>

      <div className={styles.container}>
        {children}
      </div>
    </div>
  );
};

export default Plans;
